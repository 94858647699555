import React from 'react'

export default function ContactUs () {
  return (
    <main className="blog">s
      <section className="container">
        <div className="row">
          <h2>If you are interested at my board game. Please contact Leo for more information.</h2>
          <p>Free feel to borrow and we can send you the video explanation on the game rules!</p>
          <ul>
            <li><span>Heykay studio</span></li>
            <li><span>WhatsApp: (+852) 66418466</span></li>
            <li><span>Email: heykay3dstudio@gmail.com</span></li>
          </ul>
        </div>
      </section>
    </main>
  )
}
