import * as React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import OppositeContentTimeline from './components/OppositeContentTimeLine';

interface SidebarProps {
  archives: ReadonlyArray<{
    url: string;
    title: string;
  }>;
  description: string;
  timetable: ReadonlyArray<{
    name: string;
    time: string;
    week: string;
  }>;
  social: ReadonlyArray<{
    icon: React.ElementType;
    name: string;
    url: string;
  }>;
  title: string;
}

export default function Sidebar(props: SidebarProps) {
  const { archives, description, timetable, social, title } = props;

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.200' }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography>{description}</Typography>
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Weekly Time Table
        </Typography>
        <OppositeContentTimeline/>
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Church Venue
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
          4/F, Luk Fook Jewellery Centre, 239 Temple Street, Yau Ma Tei, Kowloon, Hong Kong
        </Typography>
      </Paper>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Archives
      </Typography>
      {archives.map((archive) => (
        <Link 
          display="block" 
          variant="body1" 
          target="_blank"
          href={archive.url} 
          key={archive.title}>
          {archive.title}
        </Link>
      ))}
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Social
      </Typography>
      {social.map((network) => (
        <Link
          display="block"
          variant="body1"
          target="_blank"
          href={network.url}
          key={network.name}
          sx={{ mb: 0.5 }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <network.icon />
            <span>{network.name}</span>
          </Stack>
        </Link>
      ))}
    </Grid>
  );
}
