import moment from 'moment';

export default [
  {
    title: 'How to be productive when working from home 在家工作時如何提高工作效率',
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Daily Life', 'Working'],
    link: '#',
    image: 'wfh.jpg',
    description: "1. Wake up on time 準時起床 2. Don't sleep back when you open your eyes 睜眼時不要睡回去 3. Think of what motivates you to work 想想是什麼促使你工作 4. Find your working space at home 在家找到你的工作空間 5. If can't find then you better go back to office for work 如果找不到那麼你最好回到辦公室工作 6. Turn on Your favorite music for work 播放你最喜歡聽的音樂 7. Be sure not to be interupted by Ads 確保不被廣告打擾 8. No more excuses Just start Working 沒有更多的藉口了 請開始工作"
  },
  {
    title: 'Board Game recommendations in 2021 桌遊推薦',
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Gaming'],
    link: '/board-game',
    image: 'board_game.jpg',
    description: '1. Wingspan 2. 相手蟹之部落生存（P.S. I am the author & If you are interested at getting one Just contact me!）3. Werewords 狼人真言 4. Ocean Guardian 海洋保衛大作戰 5. Carcassonne 卡卡頌'
  },
  {
    title: 'My top 3 games on Steam 線上遊戲推薦',
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Gaming'],
    link: '#',
    image: 'steam_game.jpg',
    description: "1. Flame in the flood 2. Don't Starve Together 3. Magicka: Wizard Wars "
  },
  {
    title: "Recycling Tips 回收小貼士",
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Low Waste'],
    link: '/recycling',
    image: 'recycling.jpg',
    description: '1. Know what is Recyclable & what is not 知道什麼是可回收的，什麼不是 2. Know where is your nearest Recycling Spot 知道離您最近的回收點在哪裡 3. Sort when you Collect 收集時進行分類 4. Clean them before delivery 送貨前清潔 5. Build your habit by visiting Recycling Center once a month 每個月最少訪問一次回收中心，養成回收習慣'
  },
  {
    title: 'My Favorite Nature Spot in Hong Kong 我在香港最喜歡的自然景點',
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Weekend Activity'],
    link: '#',
    image: 'hiking.jpg',
    description: '1. Green Egg Island 綠蛋島 2. Por Lo Shan 菠蘿山 3. Lautan Island 大嶼山'
  },
]
