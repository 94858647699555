export default `
# Love that never failed

#### Oct 15, 2021 by [Chloe Tang]

## What is Love

Today is the thirteen day since my baptized on 3 Oct 2021. Baptisim is the biggest decision I have ever made in my life. I am so grateful everyday after my baptisim. I want to share with you the love I received in my life and what I want to do to give back to them :)

"Love is patient, love is kind. It does not envy, it does not boast, it is not proud. It does not dishonor others, it is not self-seeking, it is not easily angered, it keeps no record of wrongs. Love does not delight in evil but rejoices with the truth. It always protects, always trusts, always hopes, always perseveres."
1 Corinthians 13:4-7

### The Love from my Physical Family

I grew up with my mum and my elder sister. When we first move to Hong Kong, we have nothing that we need. My mum needed to take care of my dad, me and my sister all the time. Without the hard work from this women, I won't be growing up safe and sound.

### The Love from my Spiritual Family

I am now a member of my Hong Kong International Christian Church family. They are all my brothers and sisters. We are close to each other that we love to pray and read the bible together. We are all the people of his that we value God as the first prority in our lives. Without the daily encouragments from them, I won't be so confident to share the gospel.

### The Love from my Lord Jesus Christ

My Lord is the perfect man on earth. He is the holy one I am following. Without his sacrifice to die for everyone on the cross, I won't have the chance to repent and turn to God.

"Though you have not seen him, you love him; and even though you do not see him now, you believe in him and are filled with an inexpressible and glorious joy, for you are receiving the end result of your faith, the salvation of your souls." 1 Peter 1:8

How to give back

1.  Finance my life and do not let them worry
2.  Encourage one another daily and continuously have hope to bring more soul to him 
3.  Share my faith in him and invite more people to study his words together

`;