const categoryColors = {
  'Quiet Time': 'rgb(153,102,204)',
  'Daily Life': 'rgb(255,59,48)',
  'Low Waste': 'rgb(0,113,164)',
  'Weekend Activity': 'rgb(255,45,85)',
  Eating: 'rgb(52,199,89)',
  Cooking: 'rgb(64,156,255)',
  Working: 'rgb(255,179,64)',
  Gaming: 'rgb(175,82,250)'
}

export {
  categoryColors
}
