import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Main from '../Main';
import Sidebar from '../Sidebar';
import post1 from './blog-post/blog-post.1.md.js';
import post2 from './blog-post/blog-post.2.md';
import post3 from './blog-post/blog-post.3.md';

// var post1 = require('./blog-post/blog-post.1.md');
// var post2 = require('./blog-post/blog-post.2.md');
// var post3 = require('./blog-post/blog-post.3.md');
//const posts = [post1, post2, post3];
const posts = [post1];

const content = fetch(post1)
    .then(response => response.text())
    .then(text => {
        console.log(text);
    });


const sidebar = {
    title: 'About',
    description:
    `I record my daily learning from the word of God (my Bible) here. Hope it will inspire you and motivate you to join our regular Bible discussion, Mid-Week and Sunday Services :)`,
    archives: [
    { title: '3 Oct 2021', url: '#' },
    ],
    social: [
    { name: 'GitHub', icon: GitHubIcon, url: 'https://github.com/tkff1222'},
    { name: 'Twitter', icon: TwitterIcon, url: 'https://twitter.com/developingchloe'},
    ],
};    

const theme = createTheme();

export default function bibleStudy() {
    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">
        <main>
            <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="Disciple Life" posts={posts} />
            {/* <pageComponent/> */}
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
            </Grid>
        </main>
        </Container>
        </ThemeProvider>
    )
}
