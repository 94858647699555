import moment from 'moment';

export default [
  {
    title: 'How I start my day with God 我如何與上帝開始新的一天',
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Daily Life', 'Quiet Time'],
    link: '/bible-study',
    image: 'my_bible.jpg',
    description: '1. Wake up early in the morning 一大清早起床 2. Grab my NIV bible 拿起我的 NIV 聖經 3. Be ready to see the word from God 準備好看來自上帝的話語 4. Start by reading the title 從閱讀標題開始 5. Understand by checking dictionary 查字典理解不懂的生字 6. Realize by highlighting key sentence 間底重要的句子 7. Memorize by writing it down 寫下我的感受 8. Finish by speaking up my prayer 最後把我的禱告說出來'
  },
  {
    title: 'What to eat in a day 一天應該吃什麼',
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Daily Life', 'Eating'],
    link: '#',
    image: 'breakfast.jpg',
    description: '1. Water yourself in the morning 早上給自己補充水份 2. Eat breakfast so you can have energy to start your day 吃早餐讓你有精力開始新的一天 3. It would be great to enjoy lunchtime with your friends or colleagues 間中可以與朋友或同事一起享受午餐的時間 4. Grab yourself some snacks if you feel sleepy or tiring 如果你覺得困或累了，吃一些零食來提起精神 5. Cook dinner with your family or Eating out after work would be relaxing too 與家人一起準備晚飯或是外出就餐都是不錯的選擇'
  },
  {
    title: 'Rice cooker recipe 電飯煲食譜',
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Daily Life', 'Cooking'],
    link: '#',
    image: 'easy_meal.jpg',
    description: "1. Rice cooker is such a great invention 電飯煲真是一個偉大的發明 2. Put the rice & some veggies inside 只要把米飯和一些蔬菜放進去 3. After 15 minutes you can enjoy your meal 15分鐘後即可享用美食 4. Some of my favorite matches are tomato, tofu and spinach 我最喜歡的一些搭配是番茄、豆腐和菠菜 5. Don't forget to add seasoning before you press the button 按下按鈕前別忘了加調味料",
  },
  {
    title: "Beginner's Guide to Minimalism 極簡主義的初學者指南",
    date: moment().format('MMMM, DD, YYYY'),
    categories: ['Low Waste'],
    link: '#',
    image: 'minimalism.jpg',
    description: '1. Instead of thinking what you want, understand what you will need 與其想你想要的是什麼，不如了解你需要的是什麼 2. You only need one Purchase for one Usage 每一個用途的物品，你只需要擁有一件 3. Get yourself a quality one instead of a fast solution 給自己一個高質量的物品，而不是一個快速的解決方案'
  }
]
