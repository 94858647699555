import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import file from "./blog-post/blog-post.1.md.js";

const [markdown, setMarkdown] = useState("");

useEffect(() => {
  fetch(file)
    .then((res) => res.text())
    .then((text) => setMarkdown(text));
}, []);

export default function pageComponent() {
  return (
    <>
      <ReactMarkdown source={markdown} />
    </>
  );
}